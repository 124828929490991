// \chicken-website\src\Pages\About.js
import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about-container">
      <h1>Coming Soon!</h1>
      {/* Content of the About page */}
    </div>
  );
}

export default About;
