// \chicken-website\src\Pages\Contact.js
import React from "react";
import "./Contact.css";

function Contact() {
  console.log("testing Contact");
  return (
    <div className="Contact-container">
      /<h1>Coming Soon!</h1>
      {/* Content of the Contact page */}
    </div>
  );
}

export default Contact;
